<template>
    <dx-util-popup
      ref="inventorySearchPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :title="popupTitle"
      :full-screen="false"
      width="90vw"
      height="90vh"
      :position="'center'"
      @shown="onShownInventorySearch"
      @hidden="onHiddenInventorySearch"
    >
      <div id="inventoryList">
        <div>
          <div class="d-flex">
            <div>
              <img :src="searchData.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 80px !important; height: 80px; margin-right: 0.625rem" class="rounded text-white">
            </div>
            <div class="flex-grow-1">
              <span class="text-info" style="font-size: 20px; font-weight: 500">
                {{ searchData.productName }}
                <p-icon role="button" class="ml-1 rounded search-button" name="bi-search" @click.native="searchAndCopy(searchData.productName)" />
                <span v-if="searchData.isAdditionalItem" class="mx-1 badge badge-light-primary">ADDITIONAL ITEM</span>
              </span>
              <div class="d-flex">
                <div v-if="searchData.orderSku">
                  <small class="text-muted">SKU</small>
                  <span>
                  {{ searchData.orderSku }}<p-icon role="button" class="mx-1 rounded search-button" name="bi-search" @click.native="searchAndCopy(searchData.orderSku)" />
                </span>
                </div>
                <div v-if="searchData.asin">
                  <small class="text-muted">ASIN</small>
                  <span>
                  {{ searchData.asin }}
                    <p-icon role="button" class="ml-1 rounded search-button" name="bi-search" @click.native="searchAndCopy(searchData.asin)" />
                </span>
                </div>
                <div v-if="searchData.upc">
                  <small class="text-muted">SKU</small>
                  <span>
                  {{ searchData.upc }}
                    <p-icon role="button" class="ml-1 rounded search-button" name="bi-search" @click.native="searchAndCopy(searchData.upc)" />
                </span>
                </div>
              </div>
              <div v-if="isBulk && orderNumbers" class="d-flex text-center">
                Order IDs: {{ orderNumbers.toString() }}
              </div>
            </div>
            <div class="d-flex custom-border">
            </div>
              <div v-if="!isBulk && !isMarkAsShipped" class="text-center">
                <div class="d-flex">
                  <div style="margin-right: 5px;">
                    <small class="text-muted">
                      Quantity
                    </small>
                    <h4>{{ searchData.orderQuantity || '-' }}</h4>
                  </div>
                  <div>
                    <small class="text-muted">Shipped</small>
                    <h4>{{ searchData.shippedQuantity || '-' }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="d-flex">
          <dx-util-text-box
            id="code-label-text"
            v-model.trim="searchBoxValue"
            mode="search"
            placeholder="Please enter search data (UPC, MSKU ...)"
            class="flex-grow-1 my-1"
            :on-enter-key="updateList"
            @value-changed="updateList"
          />
        </div>
        <dx-util-list
          id="availableInventoryList"
          ref="availableInventoryRef"
          :height="!isBulk && !isMarkAsShipped ? '55vh' : '65vh'"
          :data-source="listDataSource"
          selection-mode="single"
          :bounce-enabled="true"
          :use-native-scrolling="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          :next-button-text="'Load More'"
          page-load-mode="nextButton"
          @item-click="listSelectionChanged"
        >
          <template #item="{ data }">
            <div class="row">
              <div class="col-10">
                <div class="d-flex">
                  <img :src="data.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 80px !important; height: 80px !important; margin-right: 0.625rem" class="rounded text-white">
                  <div class="flex-grow-1">
                    <h5 class="text-wrap text-warning mb-half">
                      {{ data.title }}
                    </h5>
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <div>
                          <copy-button :copy-value="data.upc" />
                          <small class="text-primary">UPC: </small>
                          <span>{{ data.upc }}</span>
                        </div>
                        <div>
                          <copy-button :copy-value="data.asin" />
                          <small class="text-primary">ASIN: </small>
                          <span>{{ data.asin }}</span>
                        </div>
                        <div>
                          <copy-button :copy-value="data.fnsku" />
                          <small class="text-primary">FNSKU: </small>
                          <span>{{ data.fnsku }}</span>
                        </div>
                      </div>
                      <div class="w-50">
                        <div>
                          <copy-button :copy-value="data.msku" />
                          <small class="text-primary">MSKU: </small>
                          <span>{{ data.msku }}</span>
                        </div>
                        <div>
                          <copy-button :copy-value="data.palletId" />
                          <small class="text-primary">Exp Date: </small>
                          <span>{{ data.palletId }}</span>
                        </div>
                        <div>
                          <copy-button :copy-value="data.trackingNumber" />
                          <small class="text-primary">Tracking #: </small>
                          <span>{{ data.trackingNumber }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="">
                  <div class="d-flex">
                    <div class="text-center mx-half">
                      <small class="text-muted">
                        RESERVED
                      </small>
                      <h6 :class="`${data.reserved === 0 ? '' : 'text-warning'}`">
                        {{ data.reserved }}
                      </h6>
                    </div>
                    <div class="text-center mx-half">
                      <small class="text-muted">
                        SENT
                      </small>
                      <h6 :class="`${data.sentCount === 0 ? '' : 'text-primary'}`">
                        {{ data.sentCount }}
                      </h6>
                    </div>
                    <div class="text-center mx-half">
                      <small class="text-muted">
                        AVAILABLE
                      </small>
                      <h3 :class="`${data.quantity === 0 ? '' : 'text-success'}`">
                        {{ data.quantity }}
                      </h3>
                    </div>
                  </div>
                  <div class="mt-1">
                    <span class="text-muted">
                      Location:
                    </span>
                    <strong class="text-warning">
                      {{ data.locationName || '-' }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </dx-util-list>
      </div>
      <div class="d-flex justify-content-end align-items-start" style="gap: 4px; padding-top: 6px;">
        <div v-if="!isBulk && !isMarkAsShipped" class="d-flex justify-content-end">
          <dx-util-number-box v-if="searchData.isAdditionalItem" v-model="additionalItemsQty" :min="1" :show-spin-buttons="true" label="Items Qty" :width="200">
            <dx-util-validator>
              <dx-util-required-rule message="Number is required a field" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-number-box v-else v-model="remainingQty" :min="1" :show-spin-buttons="true" label="Items Qty" :width="200">
            <dx-util-validator>
              <dx-util-required-rule message="Number is required a field" />
            </dx-util-validator>
          </dx-util-number-box>
        </div>
        <div class="text-right" style="padding-top: 6px">
          <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-half" @click="closePopup" />
          <dx-util-button text="Select Item" type="success" style="width:100px" @click="selectItem" />
        </div>
      </div>
    </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import DataSource from 'devextreme/data/data_source'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import fbmItemReservation from '@/http/requests/fbm/fbmItemReservation'
import { v4 as uuidv4 } from 'uuid'
import { useWindowSize } from '@vueuse/core'
import { availableItemsStore } from './availableItemStore'
import useFbmState from '../useFbmStates'
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'
import fbmService from '@/http/requests/fbm/fbmService'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    searchData: {
      type: Object,
      required: true,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    isMarkAsShipped: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getReservedItems, getFbmOrder, fbmOrderReloadNeeded } = useFbmState()
    const { width: windowWidth } = useWindowSize()
    const { orderNumbers, selectedOrders } = useBuyBulkLabels()
    return {
      getReservedItems, getFbmOrder, fbmOrderReloadNeeded, windowWidth, orderNumbers, selectedOrders,
    }
  },
  data() {
    return {
      popupTitle: '',
      selectedProduct: {},
      availableItemsStore,
      searchBoxValue: '',
      reservationQuantity: 0,
      remainingQty: 1,
      additionalItemsQty: 1,
      listDataSource: new DataSource({
        store: availableItemsStore,
        paginate: true,
        pageSize: 20,
      }),
    }
  },
  computed: {
    inventorySearchPopup() {
      return this.$refs.inventorySearchPopupRef.instance
    },
    dataSource() {
      return this.$refs.availableInventoryRef.instance.getDataSource()
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.searchBoxValue = ''
        this.inventorySearchPopup.show()
        this.remainingQty = this.searchData.orderQuantity - this.searchData.shippedQuantity
        this.getAvailableProducts()
      },
    },
  },
  methods: {
    onShownInventorySearch() {
      this.popupTitle = 'Inventory Search'
    },
    onHiddenInventorySearch() {
      this.selectedProduct = {}
      this.additionalItemsQty = 1
    },
    closePopup() {
      this.inventorySearchPopup.hide()
      this.selectedProduct = {}
      this.itemQuantity = 0
      this.reservationQuantity = 0
      this.totalReservedQuantity = 0
    },
    async getAvailableProducts() {
      InventorySearchFilter.setDefaultFilters()
      InventorySearchFilter.storeId = this.searchData.storeId
      InventorySearchFilter.q = ''

      const filters = InventorySearchFilter.getFilters()
      if (filters) {
        this.dataSource.searchValue(filters)
        this.dataSource.load()
      }
    },
    listSelectionChanged(e) {
      const item = e.itemData
      this.selectedProduct = item
      this.itemQuantity = item.quantity
      this.reservationQuantity = 0

      if (((this.selectedItem.orderQuantity - this.totalReservedQuantity) > 0)
        && item.quantity >= (this.selectedItem.orderQuantity - this.totalReservedQuantity)) {
        this.reservationQuantity = this.selectedItem.orderQuantity - this.totalReservedQuantity
      }
      if (((this.selectedItem.orderQuantity - this.totalReservedQuantity) > 0)
        && item.quantity < (this.selectedItem.orderQuantity - this.totalReservedQuantity)) {
        this.reservationQuantity = item.quantity
      }
    },
    async searchAndCopy(value) {
      await navigator.clipboard.writeText(value)
      this.searchBoxValue = value
    },
    updateList() {
      InventorySearchFilter.q = this.searchBoxValue
      const filters = InventorySearchFilter.getFilters()
      if (filters) {
        this.dataSource.searchValue(filters)
        this.dataSource.reload()
      }
    },
    async selectItem() {
      if (!this.selectedProduct?.id) {
        Notify.warning('Please select a product')
        return
      }
      if (this.isMarkAsShipped) {
        this.$emit('emit-item-selected', this.selectedProduct)
        this.closePopup()
        return
      }
      if (this.isBulk) {
        const reservations = []
        this.selectedOrders.forEach(order => {
          reservations.push({
            fbmOrderItemId: order.fbmOrderItemId,
            productId: this.selectedProduct.id,
          })
        })
        await fbmService.makeBulkReservation(reservations)
        this.$emit('emit-item-selected', this.selectedProduct)
        this.closePopup()
        return
      }
      const payload = {
        fbmOrderItemId: this.searchData?.fbmOrderItemId,
        reservedCount: this.remainingQty || this.additionalItemsQty,
        productId: this.selectedProduct.productId,
        itemSku: this.selectedProduct.msku,
        expirationDate: this.selectedProduct.expirationDate,
        additionalItems: this.searchData.isAdditionalItem,
      }
      await fbmItemReservation.createReservation(payload)
      await this.getReservedItems(this.searchData?.orderId)
      this.fbmOrderReloadNeeded = uuidv4()
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" >
#inventoryList {
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: rgba(30, 41, 39, 0.2);
    color: rgb(226, 238, 236);
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: rgba(27, 24, 39, 0.3);
  }
}
.search-button:hover {
  color: rgba(30, 177, 245, 0.63);
}
</style>
