import CustomStore from 'devextreme/data/custom_store'
import fbmItemReservation from '@/http/requests/fbm/fbmItemReservation'
import Pager from '@core/dev-extreme/utils/pager'

const availableItemsStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)

    const pageableQuery = `${pager.toCreatePageable()}`
    let fbmItemReservationData

    if (loadOptions.searchValue) {
      const filters = (loadOptions.searchValue)
      fbmItemReservationData = fbmItemReservation
        .getAvailableItems(filters, pageableQuery)
        .then(response => {
          const data = response
          return {
            data: data.content,
            totalCount: data.totalElements,
          }
        })
    }
    return fbmItemReservationData
  },
})

export {
  availableItemsStore,
}
